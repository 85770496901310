import Vue from 'vue'
import VueRouter from 'vue-router'
import AllPodcasts from '../views/Podcasts.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/podcasts',
    name: 'AllPodcasts',
    component: AllPodcasts
  },
  {
    path: '/podcast-groups/:podcastdate',
    name: 'AllPodcastGroups',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/AllPodcastGroups.vue')
  },
  {
    path: '/',
    name: 'Info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/Info.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
