<template>
    <v-footer app>
      <!-- -->
      <Player v-bind:podcastObject="currentPlayingPodcast" ></Player>
    </v-footer>
</template>

<script>
import {mapGetters  } from 'vuex';
import Player from "@/components/Player.vue"

export default {
    components: { Player },

    name: 'Footerbar',

    data(){
        return {}
    },
    computed : mapGetters(['currentPlayingPodcast']),
    methods : {},
    created (){
        return ;
    }
    
}
</script>

}