<template>
  <div class="home">
    <span class="text-h6">Podcasts</span>  
    <v-container class="my-1">
      <v-progress-linear v-show="podcastListLoading"
            color="success"
            indeterminate
            stream
            ></v-progress-linear>
      <v-row dense>
        <Podcast v-for="aPodcast in allPodcasts" :key="aPodcast.guid" v-bind:podcastObject="aPodcast"></Podcast>
      </v-row>
    </v-container>    
  </div>
</template>

<script>
import {mapGetters , mapActions } from 'vuex';
import Podcast from "@/components/Podcast.vue"
export default {
  name: 'PodcastList',
  components: {    
    Podcast
  },
  data(){
    return {
      podcastList :[]
    }
  },
  computed : mapGetters(['allPodcasts','podcastListLoading','currentSearchTerm']),
  methods : {
    ...mapActions(['fetchPodcasts','fetchPodcastsSearch'])
  },
  watch: {
      currentSearchTerm : function(val){
          this.fetchPodcastsSearch(val);
      }
  },
  created (){
    
    if(this.currentSearchTerm){
      this.fetchPodcastsSearch(this.currentSearchTerm);
    }else{      
      this.fetchPodcasts();
    }
    
    // set this to stop the loading icon on player
    this.$store.dispatch('setPodcastLoading',true);
    
  }


}
</script>
