import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store';

import VueAnalytics from 'vue-analytics';

import axios from 'axios';

axios.defaults.baseURL = 'https://ktm-api.deltastateonline.com/';
axios.defaults.headers.common['X-Api-Key'] = '0fS7ARRepY5R31SjP5f8N5Lm6XsnhYThar9BNHmx';

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id: 'UA-65045919-3',
  router
});

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
