<template>
  <v-col cols="12" xs="12" sm="12" md="6" lg="6"  >
      <v-card outlined tile>
        <div class="d-flex flex-no-wrap justify-space-between">
        <v-avatar class="ma-1" size="64px">                          
            <v-img                    
            :src="getImageUrl(podcastObject)"
            ></v-img>
        </v-avatar>
          <div>
        <v-card-title class="text-capitalize  text-subtitle-2 py-1 my-1">
            {{podcastObject.title}}
        </v-card-title>
        <v-card-text class="text-caption py-1 my-1" >{{podcastObject.description}}</v-card-text>
        <v-card-actions class="my-0 py-0">
          <v-btn                    
            class="ma-0 pa-0"                    
            fab
            icon
            height="40px"
            right
            width="40px"
            color="primary"
            v-on:click="playPodcast(podcastObject)"
          >
            <v-icon class="ma-0 pa-0">mdi-play</v-icon>
          </v-btn>
          <div class="ma-0 pa-0">
            <v-icon x-small dense>mdi-clock</v-icon><span class="text-caption">{{getduration(podcastObject.podcast_duration)}}</span>
          </div>
        </v-card-actions>
        </div>
        
        </div>
      </v-card>
  </v-col>           
</template>


<script>

export default {
  name: 'Podcast',
  components: {
   
  },
  props:['podcastObject'],
  data: function(){

      return {}
         
  },
  computed:{     
  },
  methods:{
    getImageUrl: function(podcastObject){
        return podcastObject.show_image_url;
    },
    getduration(duration){
        return Math.floor(duration / 60).toString()+" mins";
    },
    playPodcast(podcastObject){
        this.$store.dispatch('setPlayingPodcast',podcastObject);
    }
  }
}
</script>