import Vuex from 'vuex';
import Vue from 'vue';
import podcastlists from './modules/podcastlists';


// Load Vuex
Vue.use(Vuex);

export default new Vuex.Store({

    modules: {
        podcastlists
    }
});