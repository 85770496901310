import axios from 'axios';

const state =  {    
    podcastgroups:[],
    podcasts:[],
    currentPodcast:{},
    currentLoading:false,/* used to indicate that the podcast has been clicked and will be loaded shortly */
    podcastLLoading:false,
    searchTerm:""
};

const getters =  {
    allEvents: (state) => state.dailyevents,
    allPodcastgroups: (state) => state.podcastgroups,
    allPodcasts: (state) => state.podcasts,
    currentPlayingPodcast:(state)=>state.currentPodcast,
    currentPodcastLoading:(state)=>state.currentLoading,
    podcastListLoading:(state)=>state.podcastLLoading,
    currentSearchTerm:(state)=>state.searchTerm,

};

const actions =  {
    
    async fetchPodcastgroups({ commit }){        
        const response = await axios.get("podcastgroup");       
        commit('SET_PODCASTGROUPS',response.data.Items);
    },
    async fetchPodcasts({ commit }){
        commit('SET_PODCASTLISTLOADING',true);
        const response = await axios.get("podcasts");         
        commit('SET_PODCASTS',response.data.Items);
    },
    async fetchPodcastsSearch({ commit },searchTerm){  
        commit('SET_PODCASTLISTLOADING',true);
        const response = await axios.get(`podcasts?q=${searchTerm}`);         
        commit('SET_PODCASTS',response.data.Items);
    },
    async fetchPodcastsByDate({ commit },dateGroup){   
        commit('SET_PODCASTLISTLOADING',true);     
        const response = await axios.get(`podcastsbydate/${dateGroup}`);         
        commit('SET_PODCASTS',response.data.Items);
    },
    setPlayingPodcast({ commit },podcast){
        commit('SET_PODCASTLOADING',false);
        commit('SET_PLAYINGPODCAST',podcast);        
    },
    setPodcastLoading({ commit },loading){
        commit('SET_PODCASTLOADING',loading);
    },

    setPodcastListLoading({ commit },loading){
        commit('SET_PODCASTLISTLOADING',loading);
    },

    setSearchTerm({ commit },searchTerm){
        commit('SET_SEARCHTERM',searchTerm);
    }
}

const mutations =  {
    SET_PODCASTGROUPS: (state, allItems) => (state.podcastgroups = allItems),
    SET_PODCASTS: (state, allItems) => {state.podcasts = allItems; state.podcastLLoading=false},
    SET_PLAYINGPODCAST: (state, podcast) => (state.currentPodcast = podcast),
    SET_PODCASTLOADING: (state, loading) => (state.currentLoading = loading),
    SET_PODCASTLISTLOADING: (state, loading) => (state.podcastLLoading = loading),
    SET_SEARCHTERM: (state, searchTerm) => (state.searchTerm = searchTerm),
}
export default {
    state,
    getters,
    actions,
    mutations
}