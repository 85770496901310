<template>
    <nav>
    <v-app-bar>
        <v-app-bar-nav-icon class="grey--text" @click="drawer=!drawer"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-uppercase grey--text">
            <span class="font-weight-strong mx-2">KTM|J</span>
            <span>Podcasts</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>  
            
            <v-text-field
                hide-details
                append-icon="mdi-magnify"
                single-line v-model="searchTerm" v-on:blur="searchPodcasts"
            ></v-text-field>  
        
    </v-app-bar>

    <v-navigation-drawer app class="primary" v-model="drawer">        
        <v-list>
            <v-list-item-group
                v-model="selectedItem"
                active-class="deep-purple--text text--accent-4"
            >
            <v-list-item v-for="(link,i) in links" :key="i" router :to="link.route">
                <v-list-item-icon>
                    <v-icon class="white--text">mdi-{{link.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="white--text">
                        {{link.text}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(link) in allPodcastgroups" :key="link.podcastbymonth" router :to="'/podcast-groups/'+link.podcastbymonth">                
                <v-list-item-content>
                    <v-list-item-title class="white--text">
                       <v-chip x-small>{{link.howmany}}</v-chip><span class="text-caption"> {{link.podcastbymonth}}</span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer >
    
    </nav>
</template>

<script>
import {mapGetters , mapActions } from 'vuex';
export default {
    components: { },

    name: 'PodcastMenu',

    data(){
        return {
            drawer:false,
            selectedItem: 1,
            links : [
                {icon:'home' , text:'Home', route:'/'},   
                {icon:'library' , text:'Podcasts', route:'/podcasts'},                              
            ],
            searchTerm:"Marty"
        }
    },
    computed : mapGetters(['allPodcastgroups']),
    methods : {
    ...mapActions(['fetchPodcastgroups']),
        searchPodcasts : function(){            
            this.$store.dispatch('setSearchTerm',this.searchTerm);            
            if(this.$route.path !== '/podcasts'){
                this.$router.push({name:'AllPodcasts'});
            }
        }
    },
    created (){
        this.fetchPodcastgroups()
    }    
}
</script>