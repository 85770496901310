<template>
  <v-app class="light-grey">    
    <Appbar></Appbar>
    <v-main class="ma-4">      
      <router-view/>
    </v-main>
    <Footerbar></Footerbar>
  </v-app>
</template>

<script>

//import Navbar from '@/components/Navbar';
import Appbar from '@/components/Appbar';
import Footerbar from '@/components/Footerbar';

export default {
  name: 'App',

  components: {
    Appbar,
    Footerbar
  },

  data: () => ({
    //
  }),
};
</script>
