<template>    
    <div class="mx-auto my-0" min-width="400px">
        <v-progress-linear v-show="!currentPodcastLoading"
            color="success"
            indeterminate
            stream
            ></v-progress-linear>
        <div>
        <audio controls :src="podcastObject.podcast_source_url" id="player1" ref="player1" v-on:canplay="canPlayNow">
        </audio> 
        <div class="pt-3 text-caption small">{{podcastObject.title}}</div>         
        </div>
    </div>      
</template>


<script>

import {mapGetters } from 'vuex';
export default {
  name: 'Player', 
  props:['podcastObject'],
  components:{
     
  },
  data: function(){
      return {          
        audio: undefined,
      }        
  },
  computed:{
     ...mapGetters(['currentPodcastLoading']),
  },
  methods:{
    getImageUrl: function(podcastObject){        
        return podcastObject.show_image_url;
    },    
    canPlayNow(){
        /**
         * This is a default method which is trigged when the 
         * set the value to try to hide
         **/     
        this.$store.dispatch('setPodcastLoading',true);
    }
  },
  mounted () {
        this.audio = this.$refs.player1;
        
    } 
}
</script>